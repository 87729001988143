import { publish } from "../utils/observer";

/**
 * Publish intersection state.
 */
const handleIntersect = (subject) => (entries) => {
  entries.forEach((entry) => {
    if (entry.intersectionRatio >= 0.2) {
      publish(subject, "in-view");
    } else {
      publish(subject, "out-view");
    }
  });
};

/**
 * Creates observer.
 */
const createObserver = (subject) => {
  const options = { threshold: [0.2, 0.8] };
  return new IntersectionObserver(handleIntersect(subject), options);
};

/**
 * Notify when element is in view.
 */
export const enhancer = (element) => {
  const subject = element.getAttribute("data-in-view-subject");
  const observer = createObserver(subject);

  observer.observe(element);
};
