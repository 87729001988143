import {
  APP_IMPRESSIONS_ACTIVE,
  APP_IMPRESSIONS_INACTIVE,
} from "../config/observer-subjects";

import prefersNoMotion from "../utils/prefers-no-motion";
import { publish } from "../utils/observer";

let interval = null;
let toggle = null;
const INTERVAL = 5000;

/**
 * Toggle the clear or set the interval.
 */
const toggleInterval = (fn, time) => () => {
  if (interval) {
    interval = clearInterval(interval);

    return;
  }

  interval = setInterval(fn, time);

  // Animate just after the toggle as feedback for the user.
  setTimeout(fn, 500);
};

/**
 * Initialized by a single component (only once).
 * Responsible for publishing slider updates through the observer.
 */
export const enhancer = (element) => {
  let counter = 0;
  const max = Number(element.getAttribute("data-max")) - 1;

  const count = () => {
    publish(APP_IMPRESSIONS_INACTIVE, counter);
    counter = counter === max ? 0 : counter + 1;
    publish(APP_IMPRESSIONS_ACTIVE, counter);
  };

  // Assign function to global.
  toggle = toggleInterval(count, INTERVAL);

  // Start automatic counter
  if (!prefersNoMotion) {
    toggle();
  }
};

/**
 * Initialized by toggle button.
 * Responsible for toggling the slider update state: on/off.
 */
export const handler = (element, event) => {
  // Check if the global toggle value is set with the correct value.
  if (toggle) {
    toggle();
  }
};
