import { throttle } from "@grrr/utils";
import { subscribe } from "../utils/observer";
import whenPreferringMotion from "../utils/when-preferring-motion";

// The indexes of the items that should become active, in the correct order.
const activeItemsOrder = [3, 5, 7, 4, 6];
const TIMEOUT = 2500;
let isAnimating = false;
let isInView = false;
let counter = 0;
let timeOutFunction = null;

// Helpers
const negate = (value) => `-${value}`;
const toCSSValue = (postfix) => (value) => `${Math.abs(value)}${postfix}`;
const calculatePercentage = (items) => (item, index) =>
  (index / items.length) * 100;

/**
 * Calculate transform value for each item to be placed alongside the prefix.
 */
const calculateAnimationLocations = (items) =>
  items.map(calculatePercentage(items)).map(toCSSValue("%")).map(negate);

/**
 * Animate moveContainer.
 * Calls itself with requestAnimationFrom to create a loop.
 */
const animate = (transformValues, moveElement, items, images) => () => {
  // Bail out if not animating.
  if (!isAnimating) {
    return;
  }

  // Calculate the previous index.
  const previousActiveItemIndex = activeItemsOrder[counter] - 1;

  // Count.
  const newCounter = counter >= activeItemsOrder.length - 1 ? 0 : counter + 1;

  // Get new vales based on new count.
  const activeItemIndex = activeItemsOrder[newCounter] - 1;
  const transformValue = transformValues[activeItemIndex];

  // Show the new word.
  moveElement.style.transform = `translate3d(0, ${transformValue}, 0)`;
  items[previousActiveItemIndex].setAttribute("data-visible", "false");
  items[activeItemIndex].setAttribute("data-visible", "true");

  // Show the new image.
  images[counter].setAttribute("data-visible", "false");
  images[newCounter].setAttribute("data-visible", "true");

  // Update counter value with new value.
  counter = newCounter;

  if (isAnimating) {
    timeOutFunction = setTimeout(() => {
      requestAnimationFrame(
        animate(transformValues, moveElement, items, images, newCounter)
      );
    }, TIMEOUT);
  }
};

/**
 * Called by observer when in or out of view.
 */
const handleInView = (fn) => (subject) => {
  // Handle comes into view
  if (subject === "in-view" && !isInView) {
    isInView = true;

    // Only animate coming into view when the user didn't pause the animation.
    if (isAnimating) {
      requestAnimationFrame(fn);
    }
    return;
  }

  // Handle goes out of view
  if (subject === "out-view" && isInView) {
    isInView = false;
    clearTimeout(timeOutFunction);
  }
};

const handleClick = (animationFunction) => (event) => {
  event.preventDefault();

  // Toggle
  if (isAnimating) {
    isAnimating = false;
    clearTimeout(timeOutFunction);
  } else {
    isAnimating = true;
    animationFunction();
  }
};

/**
 * Place the moveElement to align with the prefix.
 * Called on load
 */
const placeContainer = (alignmentTarget, moveElement) => () => {
  const { width } = alignmentTarget.getBoundingClientRect();
  const offset = (window.innerWidth / 100) * 1.5;

  moveElement.style.left = toCSSValue("px")(width + offset);
};

/**
 * Rolling Text Banner
 */
export const enhancer = whenPreferringMotion((element) => {
  // Selectors
  const itemSelector = element.getAttribute("data-rolling-text-banner-items");
  const imageSelector = element.getAttribute("data-rolling-text-banner-image");
  const moveElementSelector = element.getAttribute(
    "data-rolling-text-banner-move"
  );
  const triggerSelector = element.getAttribute(
    "data-rolling-text-banner-trigger"
  );

  // Cache HTML elements
  const items = Array.from(element.querySelectorAll(itemSelector));
  const images = Array.from(element.querySelectorAll(imageSelector));
  const moveElement = element.querySelector(moveElementSelector);
  const triggerElement = element.querySelector(triggerSelector);

  const transformValues = calculateAnimationLocations(items);
  const animationFunction = animate(
    transformValues,
    moveElement,
    items,
    images
  );

  // Enable animation.
  isAnimating = true;

  // Subscribe to in/out view by observer.
  subscribe("rolling-text-banner", handleInView(animationFunction));

  // Add event listener, not a Hansel handler to reuse the function.
  triggerElement.addEventListener("click", handleClick(animationFunction));
});

/**
 * Place the rolling text banner.
 */
export const placeRollingTextBanner = (element) => {
  // Selectors
  const moveElementSelector = element.getAttribute(
    "data-rolling-text-banner-move"
  );
  const alignmentTargetSelector = element.getAttribute(
    "data-rolling-text-banner-alignment-target"
  );

  // Cache HTML elements
  const moveElement = element.querySelector(moveElementSelector);
  const alignmentTarget = element.querySelector(alignmentTargetSelector);

  // Handle windowResize
  const updateContainerLocation = placeContainer(alignmentTarget, moveElement);
  window.addEventListener("resize", throttle(updateContainerLocation, 200));
  updateContainerLocation();
};
