import whenPreferringMotion from "../utils/when-preferring-motion";

/**
 * Toggle the clear or set the interval.
 */
const toggle = (
  triggerElement,
  triggerScreenReaderValue,
  playLabel,
  pauseLabel
) => () => {
  if (triggerElement.getAttribute("data-toggle-state") === "true") {
    triggerElement.setAttribute("aria-pressed", "false");
    triggerElement.setAttribute("data-toggle-state", "false");
    triggerScreenReaderValue.innerHTML = pauseLabel;

    return;
  }

  triggerElement.setAttribute("aria-pressed", "true");
  triggerElement.setAttribute("data-toggle-state", "true");
  triggerScreenReaderValue.innerHTML = playLabel;
};

/**
 * Play/pause toggle.
 * Responsible for handling the state and accessibility updates.
 */
export const enhancer = whenPreferringMotion((triggerElement) => {
  const triggerScreenReaderValue = triggerElement.querySelector(".sr-only");
  const playLabel = triggerElement.getAttribute("data-play-label");
  const pauseLabel = triggerElement.getAttribute("data-pause-label");

  triggerElement.setAttribute("aria-pressed", "false");
  triggerScreenReaderValue.innerHTML = pauseLabel;

  // Added here, not by Hansel handler, to be able to share the toggle fn.
  triggerElement.addEventListener(
    "click",
    toggle(triggerElement, triggerScreenReaderValue, playLabel, pauseLabel)
  );
});
