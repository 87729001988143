import prefersNoMotion from "./prefers-no-motion";

export default (fn) => {
  // Bail out when no motion is preferred
  if (prefersNoMotion) {
    return null;
  }

  // Return provided function.
  return fn;
};
