import { throttle } from "@grrr/utils";

/**
 * Check form validity and update disable state of submit button.
 */
const checkFormValidity = (formElement, submitButton) => {
  if (formElement.checkValidity()) {
    submitButton.disabled = false;
  } else {
    submitButton.disabled = true;
  }
};

/**
 * Make field valid
 */
const setValid = (errorElement) => {
  errorElement.innerHTML = "";
  errorElement.setAttribute("data-visible", "false");
};
/**
 * Make field invalid
 */
const setInvalid = (errorElement, message) => {
  errorElement.innerHTML = message;
  errorElement.setAttribute("data-visible", "true");
};

/**
 * Fired on each keystroke within a form item.
 */
const handleInputKeyStroke = (errorElement, formElement, submitButton) => ({
  target,
}) => {
  checkFormValidity(formElement, submitButton);

  // Remove Existing error when valid value is entered
  if (target.checkValidity()) {
    setValid(errorElement);
  }
};

/**
 * Fired on each blur within the form.
 */
const handleInputBlur = (errorElement, formElement, submitButton) => ({
  target,
}) => {
  // Set touched data-attr to allow invalid styling.
  target.setAttribute("data-touched", "true");

  if (!target.checkValidity()) {
    setInvalid(errorElement, target.validationMessage);
  } else {
    setValid(errorElement);
  }

  checkFormValidity(formElement, submitButton);
};

export const enhancer = (form) => {
  const inputs = Array.from(form.querySelectorAll("input, textarea"));
  const submitButton = form.querySelector('button[type="submit"]');

  inputs.forEach((input) => {
    const errorElement = input.parentElement.querySelector(
      ".form-item__input-error"
    );

    input.addEventListener(
      "blur",
      handleInputBlur(errorElement, form, submitButton)
    );
    input.addEventListener(
      "keyup",
      throttle(handleInputKeyStroke(errorElement, form, submitButton), 200)
    );
  });

  checkFormValidity(form, submitButton);
};
