import {
  APP_IMPRESSIONS_ACTIVE,
  APP_IMPRESSIONS_INACTIVE,
} from "../config/observer-subjects";
import { subscribe } from "../utils/observer";

const showItem = (items) => (newItemIndex) => {
  requestAnimationFrame(() => {
    items[newItemIndex].setAttribute("data-active", "true");
  });
};
const hideItem = (items) => (newItemIndex) => {
  requestAnimationFrame(() => {
    items[newItemIndex].setAttribute("data-active", "false");
  });
};

/**
 * Handles slider active state based on observer subscription.
 */
export const enhancer = (element) => {
  const itemsSelector = element.getAttribute("data-element-selector");
  const items = Array.from(element.querySelectorAll(itemsSelector));

  subscribe(APP_IMPRESSIONS_ACTIVE, showItem(items));
  subscribe(APP_IMPRESSIONS_INACTIVE, hideItem(items));
};
